@charset "utf-8";
$JiyuMinR: 'A+EqpB-游明朝体 Pr6N R', 'vuHllhWbglqnJeEJ', 'NoChattering';
$ToppGoEB: 'A+9rnz-凸版文久見出しゴシック Std EB', 'unDXzJrWbzZbaACO', 'Toppan Bunkyu Midashi Gothic Std', 'NoChattering';
$IwatUDRGoM: 'A+nbGw-イワタUD丸ゴシックM OTF Pr6N', 'HKjOjzWrBQSwBmet', 'NoChattering';
$KinuRMinOldR: 'A+4vJx-丸明オールド StdN R', 'SUSNCQuSamcVIckF', 'NoChattering';
$DNPSMinEB: 'A+nwUR-DNP秀英初号明朝 Std', 'EmQzwTuINUeURoWO', 'NoChattering';
$TypeGoR: 'A+mfCv-AXIS Font ベーシック R ProN', 'rZMVmjkfKkpLDymd', 'NoChattering';
$JiyuMinEB2: 'A+EqpB-游築見出し明朝体', 'tKRVVbvUGxWlivrL', 'NoChattering';
$JiyuKantei: 'A+EqpB-游勘亭流', 'ptTRhXeXrxKCaDRR', 'NoChattering';
$JiyuMinD: 'A+EqpB-游明朝体 Pr6N D', 'AQbKKuXlJLjFiPWJ', 'NoChattering';
$JiyuGoB: 'A+EqpB-游ゴシック体 Pr6N B', 'UBmGFUkHsleJVPHi', 'NoChattering';
$JiyuGakusanM: 'A+EqpB-游教科書体 N 横用 M', 'wnKgzwzUOaEJqdOt', 'NoChattering';
$JiyuGakusanB: 'A+EqpB-游教科書体 N 横用 B', 'ADVXzVxFNbchrUbD', 'NoChattering';
$ToppGoEBN: 'A+9rnz-凸版文久見出しゴシック StdN EB', 'zUsUQldgrYlzgPCc', 'NoChattering';
$ToppGoR: 'A+9rnz-凸版文久ゴシック Pr6N R', 'ytNcCRxSAhlXkmqF', 'NoChattering';
$ToppGoDB: 'A+9rnz-凸版文久ゴシック Pr6N DB', 'eSimbjVmSwfFchqn', 'NoChattering';
$ToppMinEB: 'A+9rnz-凸版文久見出し明朝 Std EB', 'MpuNfINsdOvqtrpp', 'NoChattering';
$ToppMinR: 'A+9rnz-凸版文久明朝 Pr6N R', 'cSEaVBIQvODuEAyN', 'NoChattering';
$IwatSGoM: 'A+nbGw-イワタ新ゴシック体M OTF Pro', 'BCDBezpkTrgtdXYE', 'NoChattering';
$IwatUDNPMinR: 'A+nbGw-イワタUD新聞明朝 OTF Pro', 'mUhfuhZvTKCbibbw', 'NoChattering';
$IwatUDNPGoR: 'A+nbGw-イワタUD新聞ゴシック OTF Pro', 'KaczOcvDVJVoRlSe', 'NoChattering';
$IwatKaishoH: 'A+nbGw-イワタ新楷書H OTF Pro', 'vuQnyUySjahVLYnK', 'NoChattering';
$IwatMinGoD: 'A+nbGw-イワタミンゴD OTF Pro', 'fyTuPedUxxqdfGEV', 'NoChattering';
$IwatMinGoE: 'A+nbGw-イワタミンゴE OTF Pro', 'IBdzuTahjFlkoOVo', 'NoChattering';
$IwatUDGoRA: 'A+nbGw-イワタUDゴシックRA 表示用 OTF Pr6N', 'VQhBqvVFDbETAUFc', 'NoChattering';
$IwatUDGoB: 'A+nbGw-イワタUDゴシックB 表示用 OTF Pr6N', 'MdISvibqXIeZtqTt', 'NoChattering';
$IwatGoOldB: 'A+nbGw-イワタ太ゴシック体オールド OTF Pr6N', 'kxUubmxdVvYGYdYS', 'NoChattering';
$IwatGoOldEB: 'A+nbGw-イワタ特太ゴシック体オールド OTF Pr6N', 'MokDTEzsybMBlhgH', 'NoChattering';
$IwatMinOldH: 'A+nbGw-イワタ極太明朝体オールド OTF Pr6N', 'qGAyYVKhcEisxBQy', 'NoChattering';
$IwatMinOldM: 'A+nbGw-イワタ中明朝体オールド OTF Pr6N', 'OddmTJEEdFIZtqCT', 'NoChattering';
$KinuGoKaedeR: 'A+4vJx-iroha 23kaede StdN R', 'ttlwpaJyoVpAfdyX', 'NoChattering';
$KinuMMGCSrR: 'A+4vJx-丸丸ゴシックCSr StdN R', 'aHkzyGKuXSSfPPgi', 'NoChattering';
$KinuShin12: 'A+4vJx-芯 StdN 12K', 'DINvfioDWqWxtQGD', 'NoChattering';
$DNPSMinL: 'A+nwUR-DNP秀英明朝 Pr6 L', 'TdqMxrcBoSZkmDEK', 'NoChattering';
$DNPSGoKinB: 'A+nwUR-DNP秀英角ゴシック金 Std B', 'yIaHrslKtKdqqYmp', 'NoChattering';
$DNPSGoKinL: 'A+nwUR-DNP秀英角ゴシック金 Std L', 'AnjmKGQvGzOykyXe', 'NoChattering';
$DNPSGoGinB: 'A+nwUR-DNP秀英角ゴシック銀 Std B', 'leZjegEdRjHzOmfG', 'NoChattering';
$DNPSGoGinL: 'A+nwUR-DNP秀英角ゴシック銀 Std L', 'ThvjkgWjOkermeFv', 'NoChattering';
$DNPSRGoB: 'A+nwUR-DNP秀英丸ゴシック Std B', 'gHXBTGMihXLvPWRR', 'NoChattering';
$DNPSRGoL: 'A+nwUR-DNP秀英丸ゴシック Std L', 'phRoiPFmFviwBFCj', 'NoChattering';
$DNPSMinMYokobuto: 'A+nwUR-DNP秀英横太明朝 Std M', 'plETFIpNHUIbWWPK', 'NoChattering';
$TypeGoM: 'A+mfCv-AXIS Font ベーシック M ProN', 'HQMFlpAptpkBBVFt', 'NoChattering';
$TypeGoEL: 'A+mfCv-AXIS Font ベーシック EL ProN', 'NtyaVgPcaTgwFwAf', 'NoChattering';
$TypeGoCondB: 'A+mfCv-AXIS Font コンデンス B ProN', 'HNONePPuLyiEZfpT', 'NoChattering';
$TypeGoCondR: 'A+mfCv-AXIS Font コンデンス R ProN', 'MyeRtXzqqrHduMNN', 'NoChattering';
$TypeGoCompM: 'A+mfCv-AXIS Font コンプレス M ProN', 'pUmzYhLPWYlzansh', 'NoChattering';
$TypeGoCompEL: 'A+mfCv-AXIS Font コンプレス EL ProN', 'XeeGAiwACPuykUCb', 'NoChattering';
$TypeRGo100H: 'A+mfCv-AXISラウンド 100 H StdN', 'PyyjMyeRWbGVqpVk', 'NoChattering';
$TypeRGo100B: 'A+mfCv-AXISラウンド 100 B StdN', 'bzNHCPcSautEstNT', 'NoChattering';
$TypeRGo50H: 'A+mfCv-AXISラウンド 50 H StdN', 'xzBGomHPhpoWNkIM', 'NoChattering';
$TypeRGo50B: 'A+mfCv-AXISラウンド 50 B StdN', 'cwAVSvoZkyUPwaSM', 'NoChattering';
$TypeMinHCM: 'A+mfCv-TP明朝 ハイコントラスト M StdN', 'HDyLcfVTjzwPFUUI', 'NoChattering';
$JiyuGoD: 'A+EqpB-游ゴシック体 Pr6N D', 'RIfanwhRxSzjBRCN', 'NoChattering';
$JiyuMinEB: 'A+EqpB-游明朝体 StdN E', 'bBZERbBwjIhrkzbd', 'NoChattering';
$TypeGoL: 'A+mfCv-AXIS Font ベーシック L ProN', 'iYmUoIUyXhVhJKuW', 'NoChattering';
$KinuMMGALrR: 'A+4vJx-丸丸ゴシックALr StdN R', 'ebdQlTtUIYTtqqAO', 'NoChattering';
$TypeGoCompR: 'A+mfCv-AXIS Font コンプレス R ProN', 'ZIzuGdiqCzIqMItI', 'NoChattering';
$JiyuGoM: 'A+EqpB-游ゴシック体 Pr6N M', 'DJJULaiBARbFBPMh', 'YuGothic', 'Yu Gothic', 'NoChattering';
