/*!
 * REALTYPE.jp portal design
 * All Rights Reserved
 */

// Core Framework
@import "bootstrap-v3";
@import "fontawesome-v470";

// CSS Parts
@import "switch-checkbox";

// Bootstrap Theme
@import "bootstrap-portal-theme";
